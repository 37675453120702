const ChatBubbleIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={`${className ? className : 'fill-current w-5 h-5'}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
    >
      <g>
        <path d='M7.313 2.86A7.103 7.103 0 000 9.715c0 1.128.293 2.236.852 3.215.16.276.206.604.13.914l-.805 3.229a.96.96 0 00.137.794.92.92 0 001.054.331l4.295-1.517a.92.92 0 10-.613-1.736l-2.143.757a.308.308 0 01-.396-.2.307.307 0 01-.004-.163l.49-1.962a.939.939 0 00-.173-.796 4.695 4.695 0 01-.983-2.865 5.264 5.264 0 015.472-5.014 5.264 5.264 0 015.473 5.013 5.017 5.017 0 01-3.457 4.663 5.237 5.237 0 01-.806.219.947.947 0 00-.747 1.128.92.92 0 001.063.685c.381-.07.755-.17 1.12-.302a6.837 6.837 0 004.667-6.392A7.104 7.104 0 007.313 2.86z'></path>
        <path d='M19.197 13.412c-.069-.3-.024-.615.125-.883A5.563 5.563 0 0020 9.863a5.875 5.875 0 00-3.974-5.481.92.92 0 00-1.196.582.94.94 0 00.575 1.15 4.05 4.05 0 012.754 3.749 3.784 3.784 0 01-.794 2.31.918.918 0 00-.173.773l.316 1.313a.308.308 0 01-.403.36l-1.39-.504a.946.946 0 00-1.204.511.923.923 0 00.54 1.206l3.572 1.295a.922.922 0 001.114-.407.943.943 0 00.09-.696l-.63-2.612z'></path>
      </g>
    </svg>
  );
};

function ChatSingleBubbleIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 10 10'
      className={`${className ? className : 'fill-current w-2.5 h-2.5'}`}
    >
      <path d='M5.002-.002a5.006 5.006 0 00-3.468 1.249 4.519 4.519 0 00-1.53 3.21c0 .732.202 1.452.585 2.088.109.18.141.392.09.594l-.55 2.099a.594.594 0 00.094.516.63.63 0 00.323.222c.13.038.27.036.399-.008l2.937-.988a.62.62 0 00.358-.307.57.57 0 00.025-.457.61.61 0 00-.323-.34.66.66 0 00-.48-.024l-1.466.494a.22.22 0 01-.211-.04.198.198 0 01-.06-.09.19.19 0 01-.003-.106l.334-1.275a.58.58 0 00-.119-.518 2.955 2.955 0 01-.674-1.861 3.353 3.353 0 011.162-2.364 3.714 3.714 0 012.578-.898 3.714 3.714 0 012.58.893A3.353 3.353 0 018.749 4.45a3.156 3.156 0 01-.672 1.873 3.43 3.43 0 01-1.69 1.16c-.179.06-.363.108-.55.142a.667.667 0 00-.418.265.599.599 0 00-.093.468.6.6 0 00.27.36.656.656 0 00.458.085c.26-.046.516-.112.765-.197a4.67 4.67 0 002.292-1.596c.571-.745.884-1.64.897-2.561A4.519 4.519 0 008.472 1.24a5.006 5.006 0 00-3.47-1.243z'></path>
    </svg>
  );
}

export { ChatBubbleIcon, ChatSingleBubbleIcon };
